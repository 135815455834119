import { BROWSER_STORAGE_KEY_TOKEN, setItemToBrowserStorage, BROWSER_STORAGE_KEY_i18_LANG, BROWSER_STORAGE_KEY_PROGRAM_CODE, BROWSER_STORAGE_KEY_PROGRAM_TYPE, BROWSER_STORAGE_KEY_CURRENCY_CODE, BROWSER_STORAGE_KEY_COMPANY_CODE, BROWSER_STORAGE_KEY_DEFAULT_PROGRAM_CODE } from "./storage.utils"
import { ROUTER_CONFIG } from "../config/routing"
import { REDIRECT_TO } from "./Constants"
import { NAVIGATE_MEMBER_DASHBOARD } from "./urlConstants"
import { getCurrentPageUri } from "."
import { _URL_PARAMS_ACCESS_TOKEN, _URL_PARAMS_LANG } from "./urlParams.utils";
import { _URL_PRM_TO_LS_MAPPING, _WHITELIST_LANGUAGES } from "../config/config";
import { isPatternMatch } from "./validation.utils";
import { store } from '../middleware/store'

/**
 * @description Method to extract the token from the URL
 * @author Somdas M
 */
export const handleQueryParamsInURL = () => {
    const { hash } = window.location
    if (hash && hash.includes("?")) {
        let queryParams = hash.split("?")
        let _appUrl = queryParams[0]
        if (queryParams.length > 1) {
            queryParams = queryParams[1].split("&")
            queryParams.map(queryParam => {
                if (queryParam && queryParam.includes("=")) {
                    const param = queryParam.split("=")
                    if (param && param.length == 2) {
                        const pattern = _URL_PRM_TO_LS_MAPPING[param[0]] ? _URL_PRM_TO_LS_MAPPING[param[0]].pattern : ["."]
                        if (isPatternMatch(pattern, getCurrentPageUri())) {
                          const plConfig = _URL_PRM_TO_LS_MAPPING.find(
                            (item) => item.enabled && item.fromPath === param[0]
                          );
                          if (plConfig) {
                            if (param[0] == _URL_PARAMS_ACCESS_TOKEN) {
                              if (!ROUTER_CONFIG.config.isCookieEnabled) {
                                setItemToBrowserStorage(
                                  plConfig.toPath,
                                  param[1]
                                );
                              }
                            } else if (param[0] == _URL_PARAMS_LANG) {
                              const language = _WHITELIST_LANGUAGES.find(
                                (l) => l.code == param[1]
                              );
                              if (language && Object.keys(language).length) {
                                setItemToBrowserStorage(
                                  plConfig.toPath,
                                  JSON.stringify(language)
                                );
                              }
                            } else {
                              setItemToBrowserStorage(
                                plConfig.toPath,
                                param[1]
                              );
                            }
                          } else {
                            if (_appUrl.includes("?")) {
                              _appUrl += `&${param[0]}=${param[1]}`;
                            } else {
                              _appUrl += `?${param[0]}=${param[1]}`;
                            }
                            console.debug(
                              "received [",
                              param[0],
                              "]=[",
                              param[1],
                              "]"
                            );
                          }
                        }

                    }
                }
            })
            window.history.pushState({}, null, _appUrl)
        }
    }
}

/**
 * @description Method to set the redirection url
 * @author Somdas M
 */
export const handlePageRedirectionURL = () => {
    let canRefresh = false
    if(store) {
        const state = store.getState()
        const { configurationReducer } =  state
        if(configurationReducer && configurationReducer.default) {
            const defaultConfig = configurationReducer.default
            const defaultProgram = defaultConfig.programs.find(program => {
                return program.isActive && program.isDefault
            })
            if (defaultProgram) {
                setItemToBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE, defaultConfig.companyCode)
                setItemToBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE, defaultProgram.programCode)
                setItemToBrowserStorage(BROWSER_STORAGE_KEY_DEFAULT_PROGRAM_CODE, defaultProgram.programCode)
                setItemToBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE, defaultProgram.programType)
                setItemToBrowserStorage(BROWSER_STORAGE_KEY_CURRENCY_CODE, defaultProgram.defaults.currency)
                canRefresh = true
            }
        }
    }
    const currentPage = getCurrentPageUri()
    const pageConfig = ROUTER_CONFIG.routes.find(config => config.url == currentPage)

    if (pageConfig && pageConfig.isProtected) {
        const { hash } = window.location
        let redirectionUrl = hash && hash.includes("#") ? hash.split("#")[1] : ''
        window.location.href = `#${ROUTER_CONFIG.default.url}?${REDIRECT_TO}=${redirectionUrl}`
        if(canRefresh) window.location.reload()
    }
}

/**
 * @description Method to redirect to the redirectTo url
 * @author Somdas M
 */
export const redirectPage = () => {
    let hasRedirectionUrl = false
    if (window.location.hash.includes("?")) {
        const queryParams = window.location.hash.split("?")
        let redirectionBaseUrl = '', params = ''
        let foundRedirectUrl = false
        queryParams.map((queryParam, idx) => {
            if (idx > 0) {
                if (queryParam.includes(REDIRECT_TO) && queryParam.includes("=") && !foundRedirectUrl) {
                    const param = queryParam.split("=")
                    redirectionBaseUrl = param[1] ? param[1] : ''
                    foundRedirectUrl = true
                } else {
                    params += queryParam
                }
            }
        })
        if (redirectionBaseUrl) {
            hasRedirectionUrl = true
            window.location = `#${redirectionBaseUrl}${params ? "?" + params : ""}`
        }
    } else if (!hasRedirectionUrl) {
        window.location = `#${NAVIGATE_MEMBER_DASHBOARD}`
    }
}